
import React, { useState,useEffect,useRef } from 'react';
import {Link} from 'react-router-dom'
import Carousel from 'react-bootstrap/Carousel'
// import Slider from "react-slick";
// import Slider1 from "react-slick";
import { NavbarCustom } from './navbarCustom';
import axios from "axios";
import moment from "moment-timezone";
import {serverPath} from "../config"
import web3 from "../web3"
import Web3 from 'web3';

import {tenupPredictionContract,PredictionAbi} from "../contractAbi"
import Typist from 'react-typist';


import ethereum from '../images/userImages/ethereum.svg';
import bitcoin from '../images/userImages/bitcoin.svg';
import ethTableLogo from '../images/userImages/ethTableLogo.svg';
import chooseMarket from '../images/userImages/chooseMarket.png';
import { useDispatch, useSelector } from 'react-redux';



function HomePage() {
  const authedUser = useSelector(s => s.authedUser.authedUser);

  const [currentTime,setCurrentTime]=useState(moment(new Date()).format("X") )
  const [roundData, setRoundData] = useState([]);
  const [liveData, setLiveData] = useState([]);
  const [upcommingData, setUpcommingData] = useState([]);
  const [pendingData, setPendingData] = useState([]);

  async function getRoundData() {
    await axios.get(`${serverPath}/round`)
    .then((res)=>{
      // currentTime > row.startTime && currentTime < row.resultTime
      var live=res.data.filter(e=>currentTime > e.startTime && currentTime < (parseInt(e.startTime) + parseInt(e.predictionTime) ) )
      var upcomming=res.data.filter(e=>currentTime < parseInt(e.startTime) )
      var pending=res.data.filter(e=>currentTime > parseInt(e.startTime) + parseInt(e.predictionTime) && e.status != "ended" )
      // console.log(live);
      // console.log(res.data);
      setRoundData(res.data)

      setLiveData(live)
      getRoundBlockchain(live)

      setUpcommingData(upcomming)

      setPendingData(pending)
      getPendingBlockchain(pending)
    })
    .catch((res)=>{
      console.log(res);
    })
  }

  async function getRoundBlockchain(liveD) {
    let web33 = new Web3(process.env.REACT_APP_NODE_URL_RPC_GET);

    var PredContract = new web33.eth.Contract(PredictionAbi, tenupPredictionContract );
    // console.log(PredContract);
    // console.log(liveD);
    // if(authedUser.address != {}){

      try{
        var merged=[]
          for(let i=0;i<liveD.length;i++){
  
            let id = liveD[i].roundID
            var potentialRoi;
           var result= await PredContract.methods.getRoundData(id).call()
          //  console.log(result);
          //  console.log("result");
            let up = parseFloat(result.updown_amounts[0])//2
            let down = parseFloat(result.updown_amounts[1])//0
           var updownTotal = up + down
            let PayoutratioUp= updownTotal ==0 ? 0: up > 0 ?  updownTotal/up : 0;
            let PayoutratioDown= updownTotal ==0 ? 0 : down > 0 ? updownTotal/down : 0 ;
    
            if(PayoutratioUp > PayoutratioDown){
            // console.log("up");
            potentialRoi = PayoutratioUp
          }else if(PayoutratioUp < PayoutratioDown){
              // console.log("down");
              potentialRoi = PayoutratioDown
            }else if(PayoutratioUp==0 && PayoutratioDown==0){
              potentialRoi=0
            }
            
            let sendData = {
              potentialRoi: potentialRoi % 1 != 0 ? potentialRoi.toFixed(2):potentialRoi,
              price: updownTotal / 1000000000000000000
            }
            merged = [...merged,{...liveD[i], ...sendData}];
            // console.log(id);
            // console.log(sendData);
            
          }
          // console.log(merged);
          setLiveData(merged)
          
  
       }catch{
        console.log("something went wrong");
  
       }
    // }
  }

  async function getPendingBlockchain(liveD) {
    let web33 = new Web3(process.env.REACT_APP_NODE_URL_RPC_GET);

    var PredContract = new web33.eth.Contract(PredictionAbi, tenupPredictionContract );
    // console.log(PredContract);
    // console.log(liveD);
    // if(authedUser.address != {}){

      try{
        var merged=[]
          for(let i=0;i<liveD.length;i++){
  
            let id = liveD[i].roundID
            var potentialRoi;
           var result= await PredContract.methods.getRoundData(id).call()
          //  console.log(result);
          //  console.log("result");
            let up = parseFloat(result.updown_amounts[0])//2
            let down = parseFloat(result.updown_amounts[1])//0
           var updownTotal = up + down
            let PayoutratioUp= updownTotal ==0 ? 0: up > 0 ?  updownTotal/up : 0;
            let PayoutratioDown= updownTotal ==0 ? 0 : down > 0 ? updownTotal/down : 0 ;
    
            if(PayoutratioUp > PayoutratioDown){
            // console.log("up");
            potentialRoi = PayoutratioUp
          }else if(PayoutratioUp < PayoutratioDown){
              // console.log("down");
              potentialRoi = PayoutratioDown
            }else if(PayoutratioUp==0 && PayoutratioDown==0){
              potentialRoi=0
            }
            
            let sendData = {
              potentialRoi: potentialRoi % 1 != 0 ? potentialRoi.toFixed(2):potentialRoi,
              price: updownTotal / 1000000000000000000
            }
            merged = [...merged,{...liveD[i], ...sendData}];
            // console.log(id);
            // console.log(sendData);
            
          }
          // console.log(merged);
          setPendingData(merged)
          
  
       }catch{
        console.log("something went wrong");
  
       }
    // }
  }

  useEffect(()=>{
    
    getRoundData()
  },[])

  

  return (
  <>
    <div className="homePage">
      <div className="container">
        <div className="bannerSec">
        <Typist hideWhenDone={false} avgTypingDelay={200} startDelay={500} > 
          <h1><span>Make Wise Choices</span> <br /> with TenUp</h1>
          </Typist>
        </div>
      </div>
    {/* live round */}
    <section className='chooseMarket'>
      <div className="container">
          <h1>Live Rounds</h1>
          <p className='headTitle'>You have a variety of skills, so use them to make wise choices and analyze how markets will react.</p>
          <div className="row ai-center my-4">
            {liveData.map((row,i)=>(
              <div className="col-lg-3 col-sm-6">
                {/* {console.log(row)} */}
                <Link to={`/round/${row.roundID}`} className='td-none'>
                  <ChooseCard msg="Analyze With In" status="live" pair={row?.pairIdRef?.pairName} image={row?.pairIdRef?.tokenImage} pairName={row.pairName} amount2={row.price} roi={row.potentialRoi} startTime={row.startTime} predictionTime={row.predictionTime} type={row.type}  />
                </Link>
              </div>
            ))}
          
            <div className="col-lg-3 col-sm-6">
              <div className="chooseImg">
                <img src={chooseMarket} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
      </div>
    </section>

    {/* Upcoming round */}
    <section className='chooseMarket'>
      <div className="container">
          <h1>Up Coming Rounds</h1>
          <p className='headTitle'>You have a variety of skills, so use them to make wise choices and analyze how markets will react.</p>
          <div className="row ai-center my-4">
            {upcommingData.map((row,i)=>(
              <div className="col-lg-3 col-sm-6">
                <Link to={`/round/${row.roundID}`} className='td-none'>
                  <ChooseCard msg="Starting In" status="Up coming" pair={row?.pairIdRef?.pairName} image={row?.pairIdRef?.tokenImage} pairName={row.pairName} amount2={"0"} roi={"0"} startTime={row.startTime} predictionTime="0" type={row.type}  />
                </Link>
              </div>
            ))}
          
            <div className="col-lg-3 col-sm-6">
              <div className="chooseImg">
                <img src={chooseMarket} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
      </div>
    </section>
    
    {/* Pending round */}
    <section className='chooseMarket'>
      <div className="container">
          <h1>Pending Rounds</h1>
          <p className='headTitle'>You have a variety of skills, so use them to make wise choices and analyze how markets will react.</p>
          <div className="row ai-center my-4">
            {pendingData.map((row,i)=>(
              <div className="col-lg-3 col-sm-6">
                <Link to={`/round/${row.roundID}`} className='td-none'>
                  <ChooseCard msg="Settling In" status="Pending" pair={row?.pairIdRef?.pairName} image={row?.pairIdRef?.tokenImage} pairName={row.pairName} amount2={row.price} roi={row.potentialRoi} startTime={row.resultTime} predictionTime="0" type={row.type}  />
                </Link>
              </div>
            ))}
          
            <div className="col-lg-3 col-sm-6">
              <div className="chooseImg">
                <img src={chooseMarket} alt="" className="img-fluid" />
              </div>
            </div>
          </div>
      </div>
    </section>
    </div>
   

  </>
    
  );
}

const ChooseCard =({pairName,image,amount2,roi,startTime,predictionTime,status,msg,type,pair}) => {
  var [currentPrice,setCurrentPrice]=useState(0)
  var [day,setDay]=useState("")
  var [hours,setHours]=useState("")
  var [minutes,setMinutes]=useState("")
  var [seconds,setSeconds]=useState("")
  var day1;
  var hours1;
  var minutes1;
  var seconds1;
  useEffect(()=>{
    time()
    getCurrentPrice()
  },[])

  function time(){
    
    var timeElement, eventTime, currentTime, duration, interval, intervalId;
    interval = 1000; // 1 second
  
    // get time element
    // timeElement = document.querySelector("time");
    // calculate difference between two times
    // "2022-08-15T12:00:00"
    // let eveTime=parseInt(startTime) + parseInt(predictionTime)
    let eveTime1=moment.unix(parseInt(startTime) + parseInt(predictionTime)).format('YYYY-MM-DDTHH:mm:ss')
    // console.log(eveTime1);
    eventTime = moment(eveTime1);
    // based on time set in user's computer time / OS
    currentTime = moment.tz();
    // console.log(currentTime);
    // console.log(eventTime);
    // get duration between two times
    duration = moment.duration(eventTime.diff(currentTime));
    // console.log(duration);

    // loop to countdown every 1 second
    setInterval(function() {
      // get updated duration
      duration = moment.duration(duration - interval, 'milliseconds');
      // console.log(duration);
      
  
      // if duration is >= 0
      if (duration.asSeconds() <= 0) {
        clearInterval(intervalId);
        // hide the countdown element
        // timeElement.classList.add("hidden");
        setDay("00")
        setHours("00")
        setMinutes("00")
        setSeconds("00")
      } else {
        // day1 = duration.days()
        // hours1 = duration.hours()
        // minutes1 =duration.minutes()
        // seconds1=duration.seconds()
        setDay(duration.days())
        setHours(duration.hours())
        setMinutes(duration.minutes())
        setSeconds(duration.seconds())
        // console.log('====================================');
        // console.log(seconds1);
        // console.log('====================================');
        // console.log('====================================');
        // console.log(duration.days()+":"+duration.hours()+":"+duration.minutes()+":"+duration.seconds())
        // console.log('====================================');
        // otherwise, show the updated countdown
        // timeElement.innerText = duration.years() + " years " + duration.days() + " days " + duration.hours() + " hours " + duration.minutes()  + " minutes " + duration.seconds() + " seconds";
      }
      // setDay(day1)
      // setHours(hours1)
      // setMinutes(minutes1)
      // setSeconds(seconds1)

    }, interval);
    
  }

  const getCurrentPrice = async(coin1,coin2) =>{

    let c1=pairName.split('/')[0].toUpperCase()
    let c2=pairName.split('/')[1].toUpperCase()
    // console.log(c1);
    await axios.get(`https://api.blockchain.com/v3/exchange/tickers/${c1}-${c2}`)
    .then((res)=>{
      // console.log(res.data?.last_trade_price);
      if(res.data?.last_trade_price){
        setCurrentPrice(res.data?.last_trade_price)
      }else{
        setCurrentPrice(0)
      }
      
    })
    .catch((res)=>{
      // console.log(res);
      setCurrentPrice(0)
    })
  }
  return(
    <div className="marketCard">
              <div className="col1">
                <div className="iconDiv">
                  <img src={image} alt="" />
                </div>
                <div>
                  <button className="liveBtn me-2">{status}</button>
                  <button className="weeklyBtn">{type}</button>
                </div>
              </div>  

                <div className="currencyTitle mt-3 mb-2 text-uppercase">{pairName}</div>
                <h2 className="currencyAmount ">${currentPrice}</h2>
                <div className="timeDivMain">
                  <div className='timeDiv'>
                      <div className="time">
                        <h1>{day}</h1>
                        <span>DAYS</span>
                      </div>
                      <div className="time">
                        <h1>{hours}</h1>
                        <span>HOURS</span>
                      </div>
                      <div className="time">
                        <h1>{minutes}</h1>
                        <span>MINUTES</span>
                      </div>
                      <div className="time">
                        <h1>{seconds}</h1>
                        <span>SECONDS</span>
                      </div>
                  </div>
                  <div className="msg">{msg}</div>
                </div>
                <div className="col2">
                    <div>
                      <h3 className='amount'>${amount2}</h3>
                      <p className='title'>{pair}</p>
                    </div>
                    <div className='text-right'>
                      <h3 className='amount1'>{roi}x</h3>
                      <p className='title'>potential ROI</p>
                    </div>
                </div>
            </div>
  )
}

export {HomePage};