import React,{useEffect,useState } from 'react';
import { BrowserRouter as Router, Switch, Route,useParams } from "react-router-dom";
import axios from "axios";
import moment from "moment-timezone";

import DateTimePicker from 'react-datetime-picker';
import { useDispatch, useSelector } from 'react-redux';

import uploadIcon  from "../../images/uploadIcon.svg";
import plus from "../../images/plus.svg";
import "./AssignLoadForm.css";
import {serverPath} from "../../config"
import web3 from "../../web3"
import TenupIcon from "../../images/Tenup-Icon.png"

import { NavLink } from "react-router-dom";
import { tenupErcContract } from "../../contractAbi";
import {tenupPredictionContract, PredictionAbi} from "../../contractAbi"
import { AlertModal } from '../../componentUser/alertModal';






function CreateRound() {
    const authedUser = useSelector(s => s.authedUser.authedUser.address);
    const [loader,setLoader]=useState(false)

    const [startTime, setStartTime] = useState(new Date());
    const [formVal, setFormVal] = useState({
        prediction:"",
        price:"",
        pairId:"",
        resultTime:"",
        type:"",
       
    });
    const [pairData, setPairData] = useState([]);

    const [showMsg, setShowMsg] = useState(false);
    const [msgText, setMsgText] = useState("");
    const handleCloseMsg = () => setShowMsg(false);
    const handleShowMsg = () => setShowMsg(true);

    const handleChange = (e) =>{
        setFormVal({...formVal,[e.target.name]:e.target.value})
    }
    const submit = async (e) =>{
         var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );
        //  console.log(PredContract);
        if(authedUser.length > 0){
            
            if ( formVal.prediction !="" && formVal.price != "" && formVal.pairId != "" && formVal.resultTime != "") {
                let startTimestamp=moment(startTime).format("X")
                let predictionTimeInSeconds= formVal.prediction * 60
                let resultTimeInSeconds= formVal.resultTime * 60
        
                var sendData = {
                    startTime: parseInt(startTimestamp),
                    lockPrice:parseInt(formVal.price),
                    predictTime:parseInt(predictionTimeInSeconds),
                    resultTime: parseInt(resultTimeInSeconds),
                    pairId:parseInt(formVal.pairId),
                    address:tenupErcContract,
                    type:formVal.type,
                }
                // console.log(formVal);
                // console.log(startTime);
                // console.log(sendData);
                try{
                    setLoader(true)
                      var result= await PredContract.methods.createNewRound(sendData.startTime,sendData.lockPrice,sendData.predictTime,sendData.resultTime,sendData.pairId,sendData.address).send({from:authedUser})
                    //   console.log(result);
                      if(result.status){
                        let RoundID    = result.events.NewRound.returnValues.RoundID
                        let PairID     = result.events.NewRound.returnValues.PairID
                        let ResultTime = result.events.NewRound.returnValues.ResultTime
                        let PredTime    = sendData.predictTime
                        let lockPrice    = sendData.lockPrice
                        let startTime    = sendData.startTime
                        let type    = sendData.type

                          saveToDb( PairID, ResultTime, RoundID, PredTime,lockPrice,startTime,type)
                      }else{
                        //   alert("transaction not completed")
                          handleShowMsg()
                          setMsgText("transaction not completed")
                          setLoader(false)
                      }
                  }catch(e){
                    console.log("something went wrong");
                    setLoader(false)
                    console.log(e);
                  }
             
            }else {
                // alert("Please fill all fields")
                handleShowMsg()
                setMsgText("Please fill all fields")
            }
        }else {
        //   alert("please connect wallet")
          handleShowMsg()
          setMsgText("please connect wallet")
        }

    
    }
    async function saveToDb(PairID, ResultTime, RoundID, PredTime, lockPrice, startTime,type) {
        let pairName= pairData.find(e=>e.pairId == PairID )
        // console.log(pairName._id);
        // console.log(ResultTime);
        // console.log(RoundID);
        // console.log(pairName.pairName);
        // console.log(PredTime);
        // console.log(startTime);
        // console.log(lockPrice);
        // console.log(type);
        let sendData = {
            pairId:PairID,
            pairIdRef:pairName._id,
            resultTime:ResultTime,
            roundID:RoundID,
            pairName:pairName.pairName,
            predictionTime:PredTime,
            startTime:startTime,
            lockPrice:lockPrice,
            type:type,
        }

        await axios.post(`${serverPath}/create-round`,sendData,{
            headers: {
              'Content-Type': 'application/json',
          }
          })
          .then((res)=>{
            // console.log(res);
            if(res.data.data._id){
                // alert("Round Created");
                handleShowMsg()
                setMsgText("Round Created")
                setLoader(false)
            // getPairData()
            }
          })
          .catch((res)=>{
            // console.log(res);
          })
    }

    async function getPairData() {
        await axios.get(`${serverPath}/pair-data`)
        .then((res)=>{
          console.log(res.data);
          setPairData(res.data)
        })
        .catch((res)=>{
          console.log(res);
        })
      }
  
      useEffect(()=>{
        getPairData()
      },[])

    return(
      <>
   
      <div className="row mx-0 jc-center">
      <div className="col-xl-9 col-lg-10 col-11">
            <section className="AssignLoadFormSec createRoundSec">
                    {/* text input */}
                    <div className="row mx-0 mb-2">
                        <div className="col-lg-6 mb-4">
                            <span className='title'>Start Time</span>
                            <DateTimePicker onChange={setStartTime} value={startTime} />
                            {/* <input type="date" name="" id="" placeholder="Start time" className="form-control theme-bg-color border-0 small" /> */}
                        </div>
                        <div className="col-lg-6 mb-4">
                            <span className='title'>Prediction Time in minutes</span>
                            <input type="text" onChange={(e)=>handleChange(e)} name="prediction" placeholder="Prediction Time in minutes" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-lg-6 mb-4">
                            <span className='title'>Result Anounce Time</span>
                            <input type="text" onChange={(e)=>handleChange(e)} name="resultTime" placeholder="Result anounce time" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-lg-6 mb-4">
                            <span className='title'>Price</span>
                            <input type="text" name="price" onChange={(e)=>handleChange(e)} id="" placeholder="Price" className="form-control theme-bg-color border-0 small" />
                        </div>
                        <div className="col-lg-6 mb-4">
                            <select name="pairId" onChange={(e)=>handleChange(e)} value={formVal.pairId} className="form-control theme-bg-color border-0 small">
                                <option value="">Pair id</option>
                            {pairData?.map((val,key)=>(
                                <option key={key} value={val?.pairId}>{val?.pairName}</option>
                            ))}
                            </select>
                        </div>
                        <div className="col-lg-6 mb-4">
                            <select name="type" onChange={(e)=>handleChange(e)} value={formVal.type} className="form-control theme-bg-color border-0 small">
                                <option value="">Type</option>
                                <option value="daily">Daily</option>
                                <option value="weekly">Weekly</option>
                                <option value="quarterly">Quarterly</option>
                                <option value="monthly">Monthly</option>
                            </select>
                        </div>
                        
                        <div className="col">
                            <div className="bb1pxLight"></div>
                        </div>
                    </div>
                    {/* file input */}
                   
                    <div className='mt-3'>
                        <button className="btn submitBtn" onClick={submit} >Create Round</button>
                    </div>
                    
            </section>
            </div>
            </div>
            {loader &&
            <div className="seekoLoader">
                <div className="loader">
                    <img src={TenupIcon} alt="loader" />
                </div>
            </div>
        }
         
         <AlertModal show={showMsg} handleClose={handleCloseMsg} msg={msgText} />
         
      </>
    )
  }

  export default CreateRound
