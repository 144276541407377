import React, { useState,useEffect,useRef, useMemo } from 'react';
import {Link} from 'react-router-dom'   
import axios from "axios";
import { useDispatch, useSelector } from 'react-redux';
import moment from "moment-timezone";
import web3 from "../web3"
import Web3 from 'web3';

import {serverPath} from "../config"
import {tenupPredictionContract, PredictionAbi} from "../contractAbi"

import ethereum from '../images/userImages/ethereum.svg';
import bitcoin from '../images/userImages/bitcoin.svg';
import ethTableLogo from '../images/userImages/ethTableLogo.svg';
import chooseMarket from '../images/userImages/chooseMarket.png';
import goingUp from "../images/userImages/goingUp.svg"
import goingDown from "../images/userImages/goingDown.svg"
import { AlertModal } from './alertModal';
import Pagination from './pagination/pagination';

let PageSize = 10;


export const RoundHistory = () => {
    const authedUser = useSelector(s => s.authedUser.authedUser);
    const [roundData, setRoundData] = useState([]);
    
    // const [currentTableData, setCurrentTableData] = useState([]);

    const [showMsg, setShowMsg] = useState(false);
    const [msgText, setMsgText] = useState("");
    const handleCloseMsg = () => setShowMsg(false);
    const handleShowMsg = () => setShowMsg(true);

    const [currentPage, setCurrentPage] = useState(1);


    async function getRoundData() {
        // await axios.get(`${serverPath}/user-round-data`)
        await axios.get(`${serverPath}/round`)
        .then((res)=>{
            // let currentUserData = res.data.filter( e=> e.userId.address == authedUser.address)
       
            setRoundData(res.data)
        })
        .catch((res)=>{
          console.log(res);
        })
      }

      useEffect(()=>{
            getRoundData()
      },[])

      const handleClaim = async (roundId,predId) =>{
        var PredContract = new web3.web3.eth.Contract(PredictionAbi, tenupPredictionContract );

        try{
            // setLoader(true)
              var result= await PredContract.methods.claimWinner(parseInt(roundId)).send({from:authedUser.address})
              // console.log(result);
              if(result.status){
                
                  updateToDb(predId)
              }else{
                // alert("transaction not completed")
                //   setLoader(false)
                handleShowMsg()
                setMsgText("transaction not completed")
              }
          }catch{
            console.log("something went wrong");
            // setLoader(false)
          }
      }

      const updateToDb = async (predId) => {
        let sendData = {
            predId:predId
        }
        await axios.put(`${serverPath}/prediction`,sendData,{
          headers: {
            'Content-Type': 'application/json',
        }
        })
        .then((res)=>{
          // console.log(res);
          getRoundData()
            //   setLoader(false)
         
        })
        .catch((res)=>{
          console.log(res);
        })
      }
      
      // const handlePaginate=()=>{
      //   const firstPageIndex = (currentPage - 1) * PageSize;
      //   const lastPageIndex = firstPageIndex + PageSize;
      //   let tempData = [...roundData]
      //   let sliceData = tempData.slice(firstPageIndex, lastPageIndex)
      //   console.log(sliceData);
      //   setCurrentTableData(sliceData)
      // }
      // useEffect(() => {
      //   handlePaginate()
      // }, [currentPage,roundData]);
      const currentTableData = useMemo(() => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return roundData.slice(firstPageIndex, lastPageIndex);
      }, [currentPage,roundData]);
      

      // console.log(currentTableData); 
        return(
            <div className="homePage">
                <section className='crptoMarket userContentMinHeight'>
                  <div className="container">
                      <h1>Round History</h1>
                      {/* <div className="filterRow">
                      <div className="col1">
                          <p className='head'>Filter by Asset:</p>
                          <select name="" id="" className='select'>
                          <option value="">All</option>
                          </select>
                      </div>
                      <div className="col2">
                          <p className='head'>Filter by Asset:</p>
                          <div className="linkMain">
                          <button className='btn fLink p-0'>All</button>
                          <button className='btn fLink p-0'>Rapid</button>
                          <button className='btn fLink p-0'>1-Hour</button>
                          <button className='btn fLink p-0'>Daily</button>
                          <button className='btn fLink p-0'>Weekly</button>
                          </div>
                      </div>
                      </div> */}
                      <div className="marketTable">
                      <div className='table-responsive'>
                          <table className="table table-striped">
                              <thead>
                              <tr>
                                  <th scope="col"><div className='w-mc m-auto'> Market </div></th>
                                  <th scope="col"><div className='w-mc m-auto'> Type </div></th>
                                  <th scope="col"><div className='w-mc m-auto'> Round Id </div></th>
                                  <th scope="col"><div className='w-mc m-auto'> Total Pool </div></th>
                                  <th scope="col"><div className='w-mc m-auto'> Potential ROI </div></th>
                                  <th scope="col"><div className='w-mc m-auto'> Status </div></th>
                                  <th scope="col" className='text-center'>Action</th>
                              </tr>
                              </thead>
                              <tbody>
                              {
                              currentTableData.map((val,key)=>(
                                  <HistoryTable val={val} key={key} />
                              ))
                              }
                              </tbody>
                          </table>
                      </div>
                      </div>
                      <Pagination
                        className="pagination-bar"
                        currentPage={currentPage}
                        totalCount={roundData.length}
                        pageSize={PageSize}
                        onPageChange={page => setCurrentPage(page)}
                      />
                  </div>
                </section>
            <AlertModal show={showMsg} handleClose={handleCloseMsg} msg={msgText} />

            </div>
        )
   

} 

function HistoryTable({val,key}) {
    const [currentTime,setCurrentTime]=useState(moment(new Date()).format("X") )
    const [data,setData]=useState(val)
    // console.log(val);
    async function getRoundBlockchain(liveD) {
      let web33 = new Web3(process.env.REACT_APP_NODE_URL_RPC_GET);


            var PredContract = new web33.eth.Contract(PredictionAbi, tenupPredictionContract );
            // console.log(PredContract);
            // console.log(liveD);
            // if(authedUser.address){
        
              try{
                var merged=[]
          
                    var potentialRoi;
                   var result= await PredContract.methods.getRoundData(liveD?.roundID).call()
                  //  console.log(result);
                  //  console.log("result");
                    let up = parseFloat(result.updown_amounts[0])
                    let down = parseFloat(result.updown_amounts[1])
                   var updownTotal = up + down
                   let PayoutratioUp= updownTotal ==0 ? 0: up > 0 ?  updownTotal/up : 0;
                   let PayoutratioDown= updownTotal ==0 ? 0 : down > 0 ? updownTotal/down : 0 ;
            
                    if(PayoutratioUp > PayoutratioDown){
                      potentialRoi = PayoutratioUp
                    }else if(PayoutratioUp < PayoutratioDown){
                      potentialRoi = PayoutratioDown
                    }else if(PayoutratioUp==0 && PayoutratioDown==0){
                      potentialRoi=0
                    }
                    
                    let sendData = {
                      potentialRoi:PayoutratioDown,
                      price: updownTotal / 1000000000000000000
                    }
                    merged = {...liveD, ...sendData};
                    // console.log(liveD?.roundID);
                    // console.log(sendData);
                    
                  // console.log(merged);
                  setData(merged)
                //   setLiveData(merged)
                    // setLoader(false)
                  
          
               }catch{
                console.log("something went wrong");
                    // setLoader(false)
               }
            // }
       
      }

      useEffect(()=>{
        getRoundBlockchain(val)
      },[val])

    return(
    <tr key={key}>
        <td><div className='text-uppercase w-mc font-weight-bold'><img src={data?.pairIdRef?.tokenImage} className="cIcon" alt="eth" /> {data?.pairIdRef?.pairName}</div></td>
        <td><div className='text-uppercase w-mc m-auto font-weight-bold'>{data?.type}</div></td>
        <td><div className='w-mc m-auto font-weight-bold'> 
          <Link to={`/round/${data?.roundID}`} className='td-none'>
            {data?.roundID}
          </Link>
        </div></td>
        <td><div className='w-mc m-auto font-weight-bold'>{data?.price}</div></td>
        <td><div className='w-mc m-auto font-weight-bold'>
            {data?.potentialRoi > 0 ? parseFloat(data?.potentialRoi).toFixed(2): 0}
           </div>
        </td>
        <td>
            <div className='text-uppercase w-mc m-auto font-weight-bold'>
                {currentTime > data?.startTime && currentTime < (parseInt(data?.startTime) + parseInt(data?.predictionTime) )
                && "Live"}
                {currentTime < parseInt(data?.startTime) && "upcommimg"}
                {currentTime > parseInt(data?.startTime) + parseInt(data?.predictionTime) && data?.status != "ended" && "pending" }
                {currentTime > parseInt(data?.startTime) + parseInt(data?.predictionTime) && data?.status == "ended" &&  "completed" }
            </div>
        </td>
       <td><div className='w-mc m-auto font-weight-bold'> 
       {currentTime > data?.startTime && currentTime < (parseInt(data?.startTime) + parseInt(data?.predictionTime) )
        ?
        <Link to={`/round/${data?.roundID}`} className='pridictBtn' >Predict Now</Link>
        :
        "-"
             }
     
       </div></td>
    </tr>
    )
    
}